/* eslint-disable camelcase */
import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { results, PlainTable, InternalLink, PDFExport } from '@mdc/ui';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { RESULT_TEXTS, SCAN_FILE_STATUS } from '@mdc/constants';
import EXTRACTED_DATA from './ExtractedFilesData';
import { CustomContentLoader } from '../customContentLoader/CustomContentLoader';


import './ExtractedFiles.scss';


const { ScoreHeader } = results;

const ExtractedFiles = ({ type, id, action, results, resultsLoading, scanning, fileImage, progress }) => {

    const { t, ready } = useTranslation();
    const [extracted, setExtracted] = useState(null);
    const [scanResults, setScanResults] = useState({});

    useEffect(() => {
        setScanResults(results?.scan_results);
        setExtracted(results?.extracted_files?.files_in_archive);
    }, [results]);

    const resultTexts = useMemo(() => RESULT_TEXTS(t), [t]);
    const fileInfo = results?.file_info?.display_name;
    const infoFile = results?.file_info;
    const extractedFiles = results?.extracted_files?.files_in_archive;
    const sanitizationResultsDetails = results?.process_info?.post_processing?.sanitization_details?.details;
    const dlpResults = results?.dlp_info?.hits;

    const scoreMetascanDom = useMemo(() => {
        const numberOfExtractedFiles = typeof extracted?.length === 'undefined' ? '...' : extracted.length;

        const getScanProcessText = (scanning) => {
            if (scanning === SCAN_FILE_STATUS.inQueue) {
                return t('In Queue');
            } else if (scanning === SCAN_FILE_STATUS.inProgress) {
                return t('In Progress [{{progress}}%]', { progress });
            }

            return '';
        };

        const scanProcessText = resultsLoading ? t('Loading') : getScanProcessText(scanning);

        const maxDetectedEngine = extracted && extracted.reduce((prevEngine, currentEngine) => (
            prevEngine.detected_by < currentEngine.detected_by ? currentEngine : prevEngine
        ));

        let info = '';
        let detection = '';

        switch (maxDetectedEngine?.scan_result_i) {
            case 253:
            case 9:
                info = t('Exceeded Archive Depth');
                detection = 'suspicious';
                break;
            case 1:
                info = t('Threats detected');
                detection = 'detected';
                break;
            default:
                info = t('No threats detected');
                detection = 'notDetected';
        }

        return (
            <ScoreHeader
                category={t('files in package')}
                categoryNumber={numberOfExtractedFiles}
                score={maxDetectedEngine?.detected_by}
                info={info}
                detection={detection}
                numberOfEngines={scanResults?.total_avs}
                scoreText={t('ENGINES')}
                isProcessing={resultsLoading || !!scanning}
                processImgSrc={fileImage.processing.publicURL}
                processingText={scanProcessText}
            />
        );
    }, [resultsLoading, scanning, progress, scanResults, type, id, action, extracted, t]);

    const aboutMultiscanningDom = <p className='aboutMultiscanning'>
        {t('We leverage both signature and heuristic scanning from over 35 scan engines on-premises and in the cloud to increase malware detection rates')}
    </p>;

    const tableData = useMemo(() => {
        const getExtractedData = (scanResult) => {
            if (scanResult === 1) {
                return {
                    className: 'detected',
                    result: t('Detected'),
                    icon: 'icon-close'
                };
            }

            return {
                className: 'suspicious',
                result: Object.prototype.hasOwnProperty.call(resultTexts?.resultStatus, `i${scanResult}`) ? resultTexts?.resultStatus[`i${scanResult}`] :
                    resultTexts?.resultStatus?.unknown_threat,
                icon: 'icon-warning-empty'
            };

        };

        if (extracted) {
            return extracted.map((item) => {
                const className = item.scan_result_i === 0 ? '' : getExtractedData(item.scan_result_i).className;
                const result = item.scan_result_i === 0 ? t('Undetected') : getExtractedData(item.scan_result_i).result;
                const icon = item.scan_result_i === 0 ? 'icon-checkmark-symbol' : getExtractedData(item.scan_result_i).icon;

                item.result = <span className={className}><i className={icon} />{result}</span>;
                item.scan = <span> <span className={className}>{item.detected_by}</span>/{scanResults?.total_avs}</span>;
                item.name = <InternalLink to={`/results/file/${item.data_id}/regular/overview`}>{item.display_name}</InternalLink>;
                return item;
            });
        }

    }, [extracted, results, scanResults, t]);

    const extractedFilesTableDom = useMemo(() => {
        if (!tableData || !results || resultsLoading || scanning) {
            return <CustomContentLoader />;
        }

        return <PlainTable
            columnsData={EXTRACTED_DATA(t)}
            data={tableData}
        />;

    }, [EXTRACTED_DATA, tableData, results, resultsLoading, scanning]);

    if (!ready) {
        return null;
    }

    return <section className='extractedFiles'>
        {infoFile && scanResults && fileInfo && results && (
            <Row>
                <Col>
                    <PDFExport
                        aditionalData={infoFile}
                        data={results?.scan_results}
                        types={['pdf']}
                        fileName={fileInfo}
                        results={results}
                        extractedFiles={extractedFiles}
                        sanitizationResultsDetails={sanitizationResultsDetails}
                        dlpResults={dlpResults}
                    />
                </Col>
            </Row>
        )}
        <Row>
            <Col lg={4} md={4} xs={12}>
                {scoreMetascanDom}
                {scanResults?.progress_percentage === 100 && aboutMultiscanningDom}
            </Col>
            <Col lg={8} md={8} xs={12}>
                {extractedFilesTableDom}
            </Col>
        </Row>
    </section>;
};

ExtractedFiles.propTypes = {
    type: PropTypes.string,
    id: PropTypes.string,
    action: PropTypes.string,
    results: PropTypes.object,
    resultsLoading: PropTypes.bool,
    scanning: PropTypes.string,
    progress: PropTypes.number,
    fileImage: PropTypes.object.isRequired,
};

export default ExtractedFiles;
