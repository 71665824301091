export const getVerdictName = {
    'likelyMalicious': 'HIGH RISK',
    'malicious': 'CONFIRMED THREAT',
    'suspicious': 'POTENTIALLY RISK',
    'informational': 'NO THREAT DETECTED',
    'unknown': 'UNDETERMINED',
    'benign': 'TRUSTED',
    'likely_malicious': 'HIGH RISK',
    'no_threat': 'NO THREAT DETECTED',
};
